<template>
    <div>
        <jy-dialog :title="title" :visible.sync="dialogVisible" type="table" :width="'800px'">
            <div>
                <el-row>
                    <el-col :span="8"><div>线路:{{info.routeNa}}</div></el-col>
                </el-row>
                <el-row>
                    <el-col :span="8"><div>日期:{{info.oDate}}</div></el-col>
                </el-row>
                <el-row>
                    <el-col :span="8"><div>起点站:{{info.sSiteNa}}</div></el-col>
                    <el-col :span="8"><div>终点站:{{info.eSiteNa}}</div></el-col>
                </el-row>
                <el-row>
                    <el-col :span="8"><div>运行情况:{{info.eState|statusFun}}</div></el-col>
                </el-row>
                <el-row>
                    <el-col :span="8"><div>计划发车时间:{{info.dTime}}</div></el-col>
                    <el-col :span="8"><div>实际发车时间:{{info.actualDtime}}</div></el-col>
                    <!-- <el-col :span="8"><div>车辆上线时间:{{info.routeNa}}</div></el-col> -->
                    <el-col :span="8"><div>结束时间:{{info.actualOtime}}</div></el-col>
                    <el-col :span="8"><div>运行时长:{{info.runTime}}(分钟)</div></el-col>
                    <el-col :span="8"><div>时间依据:{{'GPS'}}</div></el-col>
                    <el-col :span="8"><div>计划司机:{{info.driverNa}}</div></el-col>
                    <el-col :span="8"><div>实际司机:{{info.actualDriverNa}}</div></el-col>
                    <el-col :span="8"><div>实际工号:{{info.actualWorkNum}}</div></el-col>
                    <el-col :span="8"><div>计划车牌号:{{info.vehicleNo}}</div></el-col>
                    <el-col :span="8"><div>实际车牌号:{{info.actualVehicleNo}}</div></el-col>
                    <el-col :span="8"><div>自编号:{{info.actualCustomNo}}</div></el-col>
                    <el-col :span="8"><div>运行里程:{{info.runM}}</div></el-col>
                    <el-col :span="8" v-if="info.eState != '0'&&info.eState != '3'"><el-button type="primary" @click="click">轨迹回放</el-button></el-col>
                </el-row>
                <!-- <el-row>
                    <el-col :span="8"><div>班次状态:{{info.routeNa}}</div></el-col>
                </el-row> -->
                <el-row>
                    <el-col :span="8"><div>是否跨线路:{{info.isCrossL|cross}}</div></el-col>
                </el-row>
                <el-row>
                    <el-col :span="16"><div>班次创建时间:{{info.createT}}</div></el-col>
                </el-row>
                <!-- <el-row>
                    <el-col :span="8"><div>班次创建员:{{info.routeNa}}</div></el-col>
                </el-row> -->
                <el-row>
                    <el-col :span="24"><div>备注:{{info.remark}}</div></el-col>
                </el-row>
            </div>
        </jy-dialog>
        <track-replay ref="trackReplay"></track-replay>
    </div>
</template>
<script>
import trackReplay from "@/components/pages/admin/common/trackReplay/trackReplay";
export default {
    data() {
        return {
            title: '班次信息',
            dialogVisible: false,
            waybillId: '',
            info: {
                routeNa:'',
                oDate:'',
                sSiteNa:'',
                eSiteNa:'',
                eState:'',
                dTime:'',
                actualDtime:'',
                actualOtime:'',
                runTime:'',
                driverNa:'',
                actualDriverNa:'',
                actualWorkNum:'',
                vehicleNo:'',
                actualVehicleNo:'',
                actualCustomNo:'',
                runM:'',
                isCrossL:'',
                createT:'',
                remark:'',
            },
        }
    },
    components:{
        trackReplay,
    },
    filters:{
        statusFun(val){
            let name = ''
            switch(val){
                case '0':
                    name = '未发车'
                    break;
                case '1':
                    name = '已发车'
                    break;
                case '2':
                    name = '已完成'
                    break;
                case '3':
                    name = '过时未发车'
                    break;
            }
            return name
        },
        cross(val){
            if(val == '1'){
                return '否'
            }else{
                return '是'
            }
        },
    },
    watch:{
        dialogVisible(val){
            if(!val){
                this.info = {
                    routeNa:'',
                    oDate:'',
                    sSiteNa:'',
                    eSiteNa:'',
                    eState:'',
                    dTime:'',
                    actualDtime:'',
                    actualOtime:'',
                    runTime:'',
                    driverNa:'',
                    actualDriverNa:'',
                    actualWorkNum:'',
                    vehicleNo:'',
                    actualVehicleNo:'',
                    actualCustomNo:'',
                    runM:'',
                    isCrossL:'',
                    createT:'',
                    remark:'',
                }
            }
        },
    },
    methods: {
        init(option) {
            console.log(option)
            this.dialogVisible = true
            Object.keys(this.info).forEach(item=>{
                this.info[item] = option[item] || '-'
            })
            this.info = {
                ...option,
                ...this.info,
            }
        },
        click(){
            this.$refs.trackReplay.init(this.info, "waybill");
        },
    }
}

</script>
<style lang="scss" scoped>
.el-col{
    margin:4px 0;
    height: 30px;
}
</style>