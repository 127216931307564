<template>
    <jy-dialog title="班次说明" :visible.sync="dialogVisible" type="table" :width="'500px'">
        <div class="explain">
            <div class="box" v-for="(item,k) in list" :key="k">
                <div class="btn_box" :class="item.color?'fcolor':''">
                    <div :class="item.remark?'sign':''">班次1</div>
                    <div class="icon" v-if="item.icon">
                        <i :class="item.icon"></i>
                    </div>
                </div>
                <div>{{item.text}}</div>
            </div>
        </div>
    </jy-dialog>
</template>
<script>
export default {
    data() {
        return {
            dialogVisible:false,
            list:[
                {
                    text:'未发车的计划班次',
                },
                // {
                //     color:true,
                //     text:'未发车的非计划班次',
                // },
                {
                    icon:'el-icon-circle-check',
                    text:'已完成的班次',
                },
                {
                    icon:'el-icon-remove-outline',
                    text:'已发车未完成的班次',
                },
                {
                    icon:'el-icon-close',
                    text:'过时未发车的班次',
                },
                {
                    remark:true,
                    text:'有备注信息的班次',
                },
            ],
        }
    },
    methods: {
        init() {
            this.dialogVisible = true
        },
    }
}

</script>
<style lang="scss" scoped="explain">
.explain{
    .box{
        display: flex;
        align-items: center;
        .btn_box{
            position: relative;
            padding: 2px 10px;
            color: #fff;
            width: 80px;
            background-color: rgb(151, 135, 222);
            border-radius:10px;
            box-shadow:2px 2px 5px #808080;
            margin-right: 20px;
            height: 30px;
        }
        .fcolor{
            color: black;
            background-color: #fff;
        }
        .icon{
            position: absolute;
            right: 5px;
            top: 5px;
            font-size: 15px;
            color: #fff;
        }
        .sign::after{
            content:"*";
            color:red;
        }
        +.box{
            margin-top: 10px;
        }
    }
}
</style>