<template>
    <div id="plannedShift">
        <jy-query ref="form" :model="formData">
            <jy-query-item prop="routeId" label="线路">
                <el-select v-model="formData.routeId">
                    <el-option v-for="one in routeList" v-bind:key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item prop="vehicleNo" label="车辆">
                <el-input v-model="formData.vehicleNo"></el-input>
            </jy-query-item>
            <jy-query-item prop="driverNa" label="司机">
                <el-input v-model="formData.driverNa"></el-input>
            </jy-query-item>
            <jy-query-item prop="status" label="运行情况">
                <el-select v-model="formData.status">
                    <el-option v-for="one in typeList" v-bind:key="one.value" :label="one.label" :value="one.value"></el-option>
                </el-select>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('scheduleShiftRealTimeInforList')">查询</el-button>
                <el-button type="primary" @click="resetForm('form')" v-if="btnexist('scheduleShiftRealTimeInforReset')">重置</el-button>
                <el-row>
                    <el-button type="primary" v-on:click="explain">班次说明</el-button>
                    <el-button type="primary" @click="rotationTable" v-if="btnexist('scheduleShiftRealTimeInforActualRotation')">实际轮休情况</el-button>
                </el-row>
            </template>
        </jy-query>
        <rotation-table ref="rotationTable"></rotation-table>
        <time-calendar ref="timeCalendar" @choose="choose" @changeMonth="changeMonth"></time-calendar>
        <time-table :list="list" :canUpdate="false" @clickItem="getInfor"></time-table>
        <explain ref="explain"></explain>
        <shift-infor ref="shiftInfor"></shift-infor>
    </div>
</template>
<script>
import timeTable from '@/components/pages/admin/common/timeTable/timeTable'
import timeCalendar from '@/components/pages/admin/common/timeTable/timeCalendar'
import explain from '@/components/pages/admin/shiftRealTimeInfor/explain'
import shiftInfor from '@/components/pages/admin/shiftRealTimeInfor/shiftInfor'
import rotationTable from '@/components/pages/admin/scheduling/plannedShift/rotationTable'
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            formData: {
                routeId: '',
                vehicleNo: '',
                driverNa: '',
                status:'',
            },
            routeList: [],
            typeList:[
                {
                    label:'全部',
                    value:'',
                },
                {
                    label:'未发车',
                    value:'0',
                },
                {
                    label:'已发车',
                    value:'1',
                },

                {
                    label:'已完成',
                    value:'2',
                },
                {
                    label:'过时未发车',
                    value:'3',
                },
            ],
            monthShift:[],
            rotationList:[],

            beginDate:'',
            endDate:'',
            chooseDate:'',
            list:[],
            driverList:[],
            btnMenuId:'scheduleShiftRealTimeInfor',
        }
    },
    mixins:[btnMixins],
    components: {
        timeTable,
        timeCalendar,
        explain,
        shiftInfor,
        rotationTable,
    },
    async created(){
        this.getDate()
        await this.getRouteList()
        this.getList()
    },
    methods:{
        async getRouteList() {
            let url = '/route/info/queryRouteInfoByOrgIds'
            await this.$http.post(url).then(({ detail }) => {
                this.routeList = detail
                if(this.routeList.length){
                    this.formData.routeId = this.routeList[0].routeId
                }
            })
        },
        getDate(){
            let now = new Date()
            let nowMonth = now.getMonth()
            let nowYear = now.getFullYear()
            this.chooseDate =  now.Format('yyyy-MM-dd')
            this.beginDate = new Date(nowYear, nowMonth, 1).Format('yyyy-MM-dd')
            this.endDate = new Date(nowYear, nowMonth+1, 0).Format('yyyy-MM-dd')
            this.$nextTick(()=>{
                this.$refs.timeCalendar.init(this.beginDate,this.endDate,this.chooseDate)
            })
        },
        getList() {
            let option = {
                ...this.formData,
                oDate:this.chooseDate,
            }
            let url = '/shift/real/time/queryList'
            console.log(option)
            this.$http.post(url, option).then(({ detail }) => {
                this.list = detail
            })
        },
        // 查询表格
        oncheck() {
            this.getList()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            if(this.routeList.length){
                this.formData.routeId = this.routeList[0].routeId
            }
            this.getDate()
            this.oncheck()
        },

        choose(e){
            this.chooseDate = e.date
            this.getList()
        },
        explain(){
            this.$refs.explain.init()
        },
        getInfor(item){
            this.$refs.shiftInfor.init(item)
        },
        changeMonth(option){
            this.beginDate = option.beginDate
            this.endDate = option.endDate
        },
        async rotationTable(){
            await this.getRotationList()
            await this.getMonthAllshift()
            let driverShiftList=[]
            this.monthShift.forEach(item=>{
                if(item.driverId && !driverShiftList.some(one=>one.driverId == item.driverId)){
                    driverShiftList.push(
                        {
                            driverId:item.driverId,
                            driverNa:item.driverNa,
                            workData:this.monthShift.filter(val=>val.driverId == item.driverId).map(val=>{
                                return {
                                    oDate:val.oDate,
                                    dTime:Number(val.dTime.split(':').join('')),
                                }
                            }),
                        }
                    )
                }
            })
            console.log(this.rotationList)
            driverShiftList.forEach(item=>{
                let date = []
                this.rotationList.forEach(one=>{
                    let wList = item.workData.filter(val=>val.oDate == one.date)
                    if(!wList.length){
                        date.push({
                            date:one.date,
                            typeName:'休',
                        })
                    }else{
                        if(one.limitT){
                            let morning = !wList.some(val=>val.dTime<one.limitT)
                            let afternoon = !wList.some(val=>val.dTime>one.limitT)
                            if(morning){
                                date.push({
                                    date:one.date,
                                    typeName:'上午',
                                })
                            }
                            if(afternoon){
                                date.push({
                                    date:one.date,
                                    typeName:'下午',
                                })
                            }
                        }
                    }
                })
                item.date = date
            })

            let option = {
                beginDate:this.beginDate,
                endDate:this.endDate,
                driverList:driverShiftList,
            }
            this.$refs.rotationTable.init(option)
        },
        async getRotationList(){
            let url = '/rotation/set/queryList'
            let option = {
                routeId:this.formData.routeId,
                startTime:this.beginDate,
                endTime:this.endDate,
            }
            await this.$http.post(url,option).then(({detail})=>{
                if(detail){
                    let dlist = this.$util.getBetweenDateList(this.beginDate,this.endDate)
                    let newDList = dlist.map(one=>{
                        let obj = detail.filter(item=>item.rotationPlanT == one.date)[0]
                        return {
                            date:one.date,
                            limitT:obj?Number(obj.limitT.split(':').join('')):'',
                        }
                    })
                    this.rotationList = newDList
                }else{
                    this.rotationList = []
                }
            })
        },
        async getMonthAllshift(){
            let url = '/waybillPlan/queryListByMonth'
            let option = {
                routeId:this.formData.routeId,
                startTime:this.beginDate,
                endTime:this.endDate,
            }
            await this.$http.post(url,option).then(({detail})=>{
                if(detail){
                    this.monthShift = detail
                }else{
                    this.monthShift = []
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped="plannedShift">
#plannedShift{
}
</style>